import {
  AuthClientEvent,
  AuthClientTokens,
} from '@react-keycloak/core/lib/types';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import './assets/css/App.scss';
import './assets/css/skeleton-loading.scss';
import 'react-datasheet-grid/dist/style.css';
import { AppRouter } from './routes';
import Loading from './shared/components/loading';
import useSessionStorage from './shared/hook/useSessionStorage';
import './shared/i18n';
import { useGetOidcConfigurationQuery } from './shared/services/publicApi';
import { useAppDispatch } from './shared/state/redux-hooks';
import { registerKeycloakToken } from './shared/state/slices/token/tokenSlice';
import Utils from './shared/utils';
import Keycloak from 'keycloak-js';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [kcConfig, setKcConfig] = useSessionStorage('kcConfig', {});
  const {
    data: oidc,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetOidcConfigurationQuery(undefined, {
    skip: kcConfig?.clientId,
  });

  const kc = new Keycloak(kcConfig);
  useEffect(() => {
    if (isSuccess && oidc?.clientId) {
      setKcConfig(oidc);
    }
  }, [oidc, setKcConfig, isSuccess, kcConfig]);

  const refreshError = (eventType: AuthClientEvent) => {
    if (eventType === 'onAuthRefreshError') {
      kc.logout({
        redirectUri: `${Utils.getBaseUrl(
          new URL(window.location.href),
        )}/unauthorized?from=${window.location.href}`,
      }).then(() => kc.clearToken());
    }
  };

  const tokenLogger = async (tokens: AuthClientTokens) => {
    dispatch(registerKeycloakToken(tokens.token));
  };

  if (!kcConfig?.clientId || isLoading || isFetching) {
    return <Loading message="loading.oidc" />;
  }
  return (
    <ReactKeycloakProvider
      authClient={kc}
      onEvent={refreshError}
      onTokens={tokenLogger}
      initOptions={{
        onLoad: 'check-sso',
        checkLoginIframe: false,
        pkceMethod: 'S256',
      }}
    >
      <React.StrictMode>
        <AppRouter />
        <ToastContainer role="alert" limit={3} transition={Slide} />
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
};

export default App;
